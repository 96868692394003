import { forwardRef, RefObject, useMemo } from 'react';

import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputProps as ChakraInputProps,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { debounce } from 'lodash';
import { BsFillCheckCircleFill } from 'react-icons/bs';

import { FormDebounceInputProps } from '@/elements/FormDebounceInput';
import FormLabel from '@/elements/FormLabel';

type InputProps = ChakraInputProps &
  FormDebounceInputProps & {
    error?: string | undefined;
    containerProps?: FormControlProps;
    isSuccess?: boolean;
  };

const DebounceInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error,
      hasTooltip,
      label,
      tooltipText,
      variant = 'primary',
      containerProps,
      id,
      hasLeftElement,
      leftElement,
      hintText,
      isInvalid,
      debounceTimeout = 1500,
      isSuccess,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const inputId = useMemo(() => id || `${Date.now()}-${Math.random()}`, [id]);

    return (
      <FormControl
        id={inputId}
        isInvalid={isInvalid}
        isReadOnly={variant === 'readonly'}
        {...containerProps}
      >
        {label && (
          <FormLabel
            hasTooltip={hasTooltip}
            id={inputId}
            label={label}
            tooltipText={tooltipText}
          />
        )}

        {hintText && (
          <Text color='input.hint' fontSize='sm' fontWeight='500' mb='xs'>
            {hintText}
          </Text>
        )}

        <InputGroup>
          {hasLeftElement && leftElement && (
            <InputLeftElement
              color='gray.300'
              fontSize='1.2em'
              pointerEvents='none'
            >
              {leftElement}
            </InputLeftElement>
          )}
          <ChakraInput
            id={inputId}
            onChange={debounce((value) => onChange?.(value), debounceTimeout)}
            ref={ref as RefObject<HTMLInputElement>}
            variant={variant}
            {...rest}
          />

          <InputRightElement right='16px' h='full'>
            {isSuccess && (
              <Box ml='auto'>
                <BsFillCheckCircleFill fill='green' />
              </Box>
            )}
          </InputRightElement>
        </InputGroup>

        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  },
);

DebounceInput.displayName = 'DebounceInput';

export default DebounceInput;
