import { Flex, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import logo from '@/assets/images/wta-logo.png';

export const Logo = () => {
  return (
    <Flex as={Link} to={'https://www.wtatennis.com/unlocked'}>
      <Image alt='logo' h='24px' src={logo} />
    </Flex>
  );
};
