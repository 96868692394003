import { Button, ButtonGroup } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/localization/index';
import { useKeycloak } from '@/providers/Keycloak.provider';

import { useJWT } from '@/hooks/useJWT';
import { useLogout } from '@/hooks/useLogout';

import { trackEvent } from '@/utils/analyticTracks';

export const LoginAndLogOut = () => {
  const keycloak = useKeycloak();

  const { t } = useTranslation();
  const { jwt } = useJWT();
  const { logout } = useLogout();

  const goToSignup = () => {
    trackEvent({
      event: 'button_clicked',
      button_text: 'Join Now',
      location: 'Gamezone Lobby Navigation',
    });

    return keycloak.register();
  };

  const goToLogin = () => {
    if (keycloak.authenticated && !jwt) return;

    trackEvent({
      event: 'button_clicked',
      button_text: 'Login',
      location: 'Gamezone Lobby Navigation',
    });

    return keycloak.login();
  };

  return (
    <ButtonGroup>
      {!jwt && (
        <Button onClick={goToSignup} variant='primary'>
          {t(tKeys.joinNow)}
        </Button>
      )}
      {!jwt && (
        <Button onClick={goToLogin} variant='primary'>
          {t(tKeys.login)}
        </Button>
      )}
      {jwt && (
        <Button onClick={logout} variant='primary'>
          {t(tKeys.logout)}
        </Button>
      )}
    </ButtonGroup>
  );
};
