const PrimaryDarkPurple = '#2D0046'; //rgba(45,0,70,1)

const colors = {
  absoluteDark: '#000000',
  defaultGray: '#666666',

  PrimaryPurple: '#7814FF',
  PrimaryDarkPurple,
  chalk: '#FAFAF5',

  toast: {
    success: '#009656',
    error: '#E22F2F',
    text: '#FAFAF5',
  },

  appBg: {
    primaryBg: '#FFFFFF',
    secondaryBg: '#FFFFFF',
  },
  header: {
    logo: '#FAFAF5',
    text: '#FAFAF5',
    primaryBg: 'linear-gradient(180deg, #F73535 0%, #CA2828 100%)',
    secondaryBg: '#A01313',
  },
  cardLarge: {
    bg: '#FFFFFF',
    text: '#E22F2F',
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
  },
  cardSmall: {
    bg: '#FFFFFF',
    text: '#F5FA00',
    buttonBg: '#FFFFFF',
    buttonBgActive: '#FFFFFF',
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
    title: '#E22F2F',
    description: '#909090',
  },
  tile: {
    bg: '#FFFFFF',
    text: '#E22F2F',
    border: '#D9D9D9',
  },
  contestTile: {
    bg: '#33CFFF',
    text: '#111111',
  },
  timer: {
    bg: '#FFFFFF',
    text: '#F73535',
    image: '#666666',
    imageActive: '#E22F2F',
  },
  section: {
    title: '#E22F2F',
    leftImage: {
      title: '#E22F2F',
      subtitle: '#E22F2F',
      description: '#E22F2F',
    },
  },
  carousel: {
    bullet: '#E6E6E6',
    bulletActive: '#F5FA00',
  },
  langSwitcher: {
    bg: '#A01313',
    border: '#A01313',
    text: '#FAFAF5',
  },
  input: {
    bg: '#0D003B',
    text: '#FAFAF5',
    border: '#E6E6E6',
    placeholder: '#D9D9D9',
    invalid: '#E22F2F',
    cancel: '#D9D9D9',
    hint: '#D9D9D9',
    success: '#009656',
    eye: '#D9D9D9',
  },
  signup: {
    title: '#262626',
    text: '#FAFAF5',
    formBorder: '#e2e8f0',
    checkboxText: '#262626',
  },
  login: {
    title: '#262626',
    text: '#262626',
    formBorder: '#e2e8f0',
  },
  profile: {
    title: '#262626',
    text: '#262626',
    formBorder: '#e2e8f0',
  },
  resetPassword: {
    title: '#262626',
    text: '#262626',
    formBorder: '#e2e8f0',
  },
  forgotPassword: {
    title: '#262626',
    text: '#262626',
    formBorder: '#e2e8f0',
    success: '#009656',
  },
  unsubscribe: {
    title: '#262626',
    text: '#262626',
    formBorder: '#e2e8f0',
    iconButton: '#FAFAF5',
  },
  countrySelect: {
    bg: '#0D003B',
    border: '#D9D9D9',
    text: '#FAFAF5',
    menuBg: '#0D003B',
    placeholder: '#D9D9D9',
    arrow: '#262626',
    invalid: '#E22F2F',
    menu: '#0D003B',
  },
  sidebar: {
    bg: '#FFFFFF',
    close: '#FFFFFF',
    logo: '#FFFFFF',
    text: '#FAFAF5',
  },
  button: {
    defaultText: '#FAFAF5',
    primary: {
      bg: PrimaryDarkPurple,
      border: PrimaryDarkPurple,
      disabledBg: 'rgba(120,20,255,0.5)',
      text: '#FAFAF5',
    },
    secondary: {
      bg: '#000000',
      border: '#000000',
      disabledBg: 'rgba(0,0,0,0.5)',
      text: '#FAFAF5',
    },
  },
};

export default colors;
